import { BasicProductPartial } from './BasicProduct';

export const CompleteProduct = `
  ${BasicProductPartial}
  productMarcaForSeo {
    name
    slug
  }
  productCategoryForSeo {
    name
    slug
  }
  primaryCategory {
    name
    path
  }
  metaData (keysIn: ["_unique_in_suscription", "is_mirakl_product", "_non_subscribable", "_order_limit", "disable_stock_alert"]) {
    key
    value
  }
  woosbExactQuantityOptions
  woosbMinQty
`;
